<template>
  <section class="main-section main-section-join">
    <v-img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/banner/banner-join.png"></v-img>
    <div id="common-form-layout">
      <div class="join-section">
        <h3 class="join-name-div">
          <span class="join-name">加盟流程</span>
        </h3>
        <div class="join-page">
          <div class="join-content">
            <p>资质预审评估通过后，会由玛娅专人负责面谈，及签订代理协议。代理协议签订后3个月内，应落实展厅位置，6个月内展厅具备开业条件。</p><br/>
            <p>代理协议签订后，培训工作在经销商组织框架建立后同步启动，玛娅提供的全面培训体系包括：品牌、德国产品手册、德国软件、下单系统、零售技巧、安装流程、售后流程、配套产品、每年一次德国总部学习。</p><br/>
            <p>有意加入玛娅家居可<b>填写下方加入预审表</b>，若符合加入条件，我们会有专人和您联系。</p>
          </div>
        </div>
      </div>
      <v-form
        v-model="messageForm"
        ref="messageForm"
        style="width:100%; margin-top: 2.5rem;"
      >
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-subheader class="ctk-input-label">姓名</v-subheader>
          </v-col>
          <v-col cols="12" xs="12" md="12">
            <v-text-field name="name" placeholder="请输入您的姓名" filled rounded label="" :rules="[v => !!v || '请输入姓名']" v-model="formData.name"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12"  xs="12" md="12">
            <v-subheader class="ctk-input-label">手机号</v-subheader>
          </v-col>
          <v-col cols="4" xs="4" md="4">
            <v-text-field height="3.5rem" name="mobile" placeholder="请输入手机号" filled rounded label="" :rules="[ v => !!v || '请输入手机号', v => /^[1][0-9]{10}$/.test(v) || '请输入正确的手机号']" v-model="formData.mobile" ></v-text-field>
          </v-col>
          <v-col cols="4"  xs="4" md="4" style="padding-left: 1rem;">
            <v-text-field height="3.5rem" name="code" placeholder="验证码" filled rounded label="" :rules="[ v => !!v || '请输入验证码', v => /(^[\-0-9][0-9]*(.[0-9]+)?)$/.test(v) || '验证码错误']" v-model="formData.code" > </v-text-field>
          </v-col>
          <v-col cols="4"  xs="4" md="4">
            <a id="send_code_btn" @click="sendCode">获取验证码</a>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-subheader class="ctk-input-label">城市</v-subheader>
          </v-col>
          <v-col cols="12" xs="12" md="12">
            <v-text-field name="city" placeholder="请输入您的所在城市" filled rounded label="" :rules="[v => !!v || '请输入您所在城市']" v-model="formData.city"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-subheader class="ctk-input-label">投资预算</v-subheader>
          </v-col>
          <v-col cols="12" xs="12" md="12">
            <v-text-field name="budget" placeholder="请输入您的投资预算" filled rounded label="" v-model="formData.budget"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-subheader class="ctk-input-label">之前涉足行业和品牌</v-subheader>
          </v-col>
          <v-col cols="12" xs="12" md="12">
            <v-text-field name="profession" placeholder="请输入您之前涉足行业和品牌" filled rounded label="" v-model="formData.profession"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-subheader class="ctk-input-label">留言</v-subheader>
          </v-col>
          <v-col cols="12" xs="12" md="12">
            <v-textarea name="content" placeholder="请输入您的咨询内容，以便我们可以更好地为您提供服务" filled rounded label="" rows="3" v-model="formData.content" ></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-btn depressed tile class="submit-btn" @click="submitForm" width="100%" height="3.5rem">提交</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">操作提示</v-card-title>
              <v-card-text>{{returnMsg}}</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="dialog = false" >确定</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </div>
    <div class="main-layout">
      <div class="join-section">
        <h3 class="join-name-div">
          <span class="join-name">加盟资质</span>
        </h3>
        <div class="join-page" v-for="(c, qi) in qualification" :key="qi">
          <h3 class="join-title">
            <span class="join-title-text">{{ c.title }}</span>
            <span class="join-title-bg"></span>
          </h3>
          <div class="join-content" v-html="c.content"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    qualification: [
      {
        title: '法人要求',
        content: '<p>1、具备较好的商业背景和品牌建材运营经验，熟悉当地建材市场。</p><br/><p>2、具有一定管理经验，有行业相关人才及团队。</p><br/><p>3、认同玛娅家居经营理念。</p>'
      },
      {
        title: '场地要求',
        content: '<p><b>场地位置：</b>头部建材商场或城市主力商圈。</p><br/><p><b>场地面积：</b>实际使用面积不低于250平方米。</p>'
      },
      {
        title: '团队要求',
        content: '<p><b>经营负责人：</b>应具有管理能力与市场意识。具备较强的目标管理能力，能够完成年度销售目标。</p><br/><p><b>工作人员：</b>应有能力在开业之前建立完善的团队，对团队成员素质有高要求。</p>'
      },
      {
        title: '管理要求',
        content: '<p>缴纳品牌加盟费&品牌使用费。指玛娅将所拥有的注册商标、企业标志、管理模式等经营资源授权经销商使用，并用于培训、技术支持和其他市场支持。合作终止，不予退还。</p>'
      },
      {
        title: '设计配套',
        content: '<p><b>店面设计：</b>玛娅品牌方提供店面设计，以及供装修施工服务（在一定预算范围内经销商承担），以达到品牌统一性的目的。</p><br/><p><b>产品设计：</b>玛娅品牌方规划提供统一产品设计，经销商需按照指定的设计要求执行。</p><br/><p><b>电器配套：</b>由玛娅品牌方指定合作电器品牌。</p>'
      }
    ],
    messageForm: false,
    returnMsg: null,
    dialog: false,
    formData: {
      name: '',
      mobile: '',
      code: '',
      city: '',
      budget: '',
      profession: '',
      content: ''
    },
    btnDisable: false,
    itv: '',
    scd: 60
  }),
  created () {
    this.$store.commit('updateActiveNav', null)
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
    sendCode () {
      if (this.btnDisable) {
        return false
      }
      if (/^[1][0-9]{10}$/.test(this.formData.mobile)) {
        this.btnDisable = true
        document.getElementById('send_code_btn').innerText = '发送中...'
        var that = this
        this.https.get('join_code', { mobile: this.formData.mobile }).then(response => {
          if (response.code === 0) {
            that.itv = setInterval(function () {
              if (that.scd > 1) {
                that.scd -= 1
                document.getElementById('send_code_btn').innerText = '等待' + that.scd + '(s)'
              } else {
                that.btnDisable = false
                document.getElementById('send_code_btn').innerText = '发送验证码'
                that.scd = 60
                clearInterval(that.itv)
              }
            }, 1000)
          } else {
            that.btnDisable = false
            document.getElementById('send_code_btn').innerText = '发送验证码'
            that.scd = 60
            this.returnMsg = response.msg
            this.dialog = true
          }
        })
      } else {
        this.returnMsg = '请输入正确的手机号码'
        this.dialog = true
      }
    },
    submitForm () {
      this.$refs.messageForm.validate()
      if (this.messageForm === true) {
        this.https.get('online_join', this.formData).then(response => {
          if (response.code === 0) {
            this.$refs.messageForm.reset()
            document.getElementById('send_code_btn').innerText = '发送验证码'
          }
          this.returnMsg = response.msg
          this.dialog = true
        })
      }
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/about.css';
</style>
